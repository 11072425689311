import React, { useEffect, useState } from "react";
import "./style.css";
import Colade from "../layout/footer/sponsors/Colade";
import { Container, Row, Col } from "react-bootstrap";
const Organizadores = () => {
  const [contador] = useState(0);
  useEffect(() => {
    let container_all = document.getElementById("container-body");
    let header = document.getElementById("header");
    let portada = document.getElementById("portada");
    container_all.scrollTo(0, header.scrollHeight + portada.scrollHeight);
  }, [contador]);
  return (
    <Container fluid id="organizadores-container">
      <Row>
        <h1 style={{ textAlign: "center", fontWeight: "900" }}>
          ORGANIZADORES
        </h1>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={6}>
          <img
            loading="lazy"
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/LOGO-FELABAN-BLANCO.png"
            style={{ width: "100%", objectFit: "contain", padding: "5%" }}
            alt="img"
          />
        </Col>
        <Col xs={12} md={12} lg={6}>
          <p style={{ textAlign: "justify", fontSize: "1em" }}>
            La Federación Latinoamericana de Bancos, FELABAN, es una institución
            sin fines de lucro, constituida en 1965 en la ciudad de Mar del
            Plata, República Argentina. Agrupa, a través de sus respectivas
            asociaciones en 19 países del continente, a más de 623 bancos y
            entidades financieras de América Latina.
            <br />
            <br />
          </p>
          <p>
            <strong>Objetivos: </strong>
            <br />
            <br />
            Fomentar y facilitar el contacto, el entendimiento y las relaciones
            directas entre las entidades financieras de América Latina, sin
            consideración sobre asuntos de política interna de cada país.
            <br />
          </p>
          <ul>
            <li>
              Contribuir por conducto de sus servicios técnicos a la
              coordinación de criterios y a la unificación de usos y prácticas
              bancarias y financieras en general en Latinoamérica.
            </li>
            <li>
              Cooperar dentro de las actividades que le son propias, al más
              eficaz desarrollo económico de los países latinoamericanos y al de
              los movimientos de integración económica en que participen.
            </li>
            <li>
              Propender por todos los medios a su alcance el desarrollo y
              bienestar de los países en que radiquen sus miembros.
            </li>
            <li>
              Propender por una mayor profundización financiera y mayor acceso
              de los grupos poblacionales de menor ingreso a los servicios
              financieros como forma de contribuir a la disminución de la
              pobreza en los países latinoamericanos.
            </li>
          </ul>
        </Col>
      </Row>
      <Row>
        <Col xs={12} md={12} lg={6}>
          <img
            loading="lazy"
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Logo+ABG.png"
            style={{ width: "100%", objectFit: "contain", padding: "5%" }}
            alt="img"
          />
        </Col>
        <Col xs={12} md={12} lg={6}>
          <p style={{ fontSize: "1em", textAlign: "justify" }}>
            <strong>La Asociación Bancaria de Guatemala –ABG</strong>
            <br />
            <br />
            <br />
            Es la Asociación civil y de carácter gremial, integrada por todas
            las entidades Bancarias y Sociedades Financieras Privadas
            autorizadas para operar en Guatemala. Fue constituida el 8 de
            septiembre de 1961 y es Miembro Fundador de la Federación
            Latinoamericana de Bancos –FELABAN-, en la que tiene representación
            a través de sus Comités Técnicos.
            <br />
            <br />
          </p>
          <p>
            <strong>Principales Objetivos:</strong>
            <br />
            <br />
          </p>
          <ul>
            <li>
              Defender y velar por los intereses de sus miembros oportuna y
              diligentemente.
            </li>
            <li>
              Emitir opinión sobre los proyectos de ley y reglamentos, que en
              materia financiera o relacionados con el sistema bancario
              surgieren, así como gestionar ante las autoridades y organismos
              respectivos la emisión de aquellas disposiciones que considere
              convenientes, o la modificación de las existentes.
            </li>
            <li>
              Propugnar por la modernización de las prácticas bancarias y de los
              instrumentos financieros, para propiciar el ahorro y estimular la
              inversión en proyectos que beneficien el desarrollo económico
              nacional.
            </li>
            <li>
              Velar porque se mantengan las mejores condiciones financieras y de
              confianza en el País.
            </li>
            <li>
              Velar porque la estructura institucional del sistema bancario,
              permita el mejor desenvolvimiento de sus actividades en general,
              en consonancia con las prácticas modernas, tanto a nivel nacional
              como internacional.
            </li>
            <li>
              Coadyuvar para que el sistema financiero nacional mantenga una
              corriente fluida de recursos que garanticen el normal desarrollo
              de las actividades productivas del País.
            </li>
            <li>
              Prestar apoyo a sus miembros asociados a través del servicio de
              interconexión electrónica bancaria.
            </li>
          </ul>
        </Col>
      </Row>
    </Container>
  );
};

export default Organizadores;
