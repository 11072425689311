import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "./style.css";
const Portada = () => {
  return (
    <Container fluid className="header-portada">
      <Row className="d-flex justify-content-center align-items-center">
        <Col xs={4} md={4} lg={4} className="text-center">
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/logo+FELABAN+blanco.png"
            alt="header"
            className="img-portada-col"
          />
        </Col>
        <Col xs={4} md={4} lg={4} className="text-center">
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Logo+blanco+CLACE.png"
            alt="header"
            className="img-portada-col"
          />
        </Col>
        <Col xs={4} md={4} lg={4} className="text-center">
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/logo+ABG+blanco.png"
            alt="header"
            className="img-portada-col"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Portada;
