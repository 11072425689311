import "./style.css";
import React, { useEffect, useState } from "react";
import { Stack, CircularProgress, Box, Backdrop } from "@mui/material";
import Patrocinadores from "../../evento/patrocinadores/Patrocinadores";
const admin_service = require("../../../helpers/admin_service");
const Footer = () => {
  const [contador] = useState(0);
  const [evento, setEvento] = useState({});
  const [programa, setPrograma] = useState({});
  const [patrocinadores, setPatrocinadores] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    admin_service
      .getData(
        "/evento/view-by-hash/" +
          "d5b05bf19a3cd366492bddc594937618c309c860bdb53719664a51d124d1e9b1"
      )
      .then((response_evt) => {
        admin_service
          .getData(
            "/programa/view/" +
              "d5b05bf19a3cd366492bddc594937618c309c860bdb53719664a51d124d1e9b1"
          )
          .then((response_programa) => {
            admin_service
              .getData(
                "/patrocinador/view-by-evento/" +
                  response_evt.data.response_database.result[0].UniqueID
              )
              .then((response_patrocinador) => {
                setEvento(response_evt.data.response_database.result[0]);
                setPrograma(response_programa.data.response_database);
                setPatrocinadores(response_patrocinador.data.response.result);
                setLoading(false);
              })
              .catch((error) => {
                console.log(error);
              });
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });
  }, [contador]);
  return loading ? (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={loading}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  ) : (
    <Box>
      <Stack spacing={5}>
        <Patrocinadores
          evt="d5b05bf19a3cd366492bddc594937618c309c860bdb53719664a51d124d1e9b1"
          patrocinadores={patrocinadores}
        />
      </Stack>
    </Box>
  );
};

export default Footer;
