import React, { useEffect, useState } from "react";
import { Container, Col, Row } from "react-bootstrap";
import "./style.css";
const Registro = () => {
  const [contador] = useState(0);
  useEffect(() => {
    let container_all = document.getElementById("container-body");
    let header = document.getElementById("header");
    let portada = document.getElementById("portada");
    container_all.scrollTo(0, header.scrollHeight + portada.scrollHeight);
  }, [contador]);
  return (
    <Container fluid className="registro-container">
      <Row>
        <Col xs={12} md={12} lg={12}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Tarifario+CLACE+2024+(1).jpg"
            alt="tarifario"
            className="tarifario"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default Registro;
