import React, { useEffect, useState } from "react";
import { Row, Container, Col } from "react-bootstrap";
import "./style.css";
import View from "../evento/View";

const Programa = () => {
  const [contador] = useState(0);
  useEffect(() => {
    let container_all = document.getElementById("container-body");
    let header = document.getElementById("header");
    let portada = document.getElementById("portada");
    container_all.scrollTo(0, header.scrollHeight + portada.scrollHeight);
  }, [contador]);
  return (
    <Container fluid>
      <View
        evt="d5b05bf19a3cd366492bddc594937618c309c860bdb53719664a51d124d1e9b1"
        pais="GUATEMALA"
      />
    </Container>
  );
};

export default Programa;
//d5b05bf19a3cd366492bddc594937618c309c860bdb53719664a51d124d1e9b1
