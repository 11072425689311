import { Outlet } from "react-router-dom";
import { Container, Row } from "react-bootstrap";
import React, { useEffect, useState } from "react";
import Header from "./header/Header";
import Footer from "./footer/Footer";
import "./style.css";
import Portada from "./header/Portada";
const Layout = () => {
  return (
    <Container
      fluid
      style={{ height: "100vh", width: "100vw", overflow: "hidden" }}
      id="container-all"
    >
      <Row id="header" style={{ height: "5%" }}>
        <Header />
      </Row>
      <Row style={{ height: "95%", overflowY: "scroll" }} id="container-body">
        <Container fluid>
          <Row id="portada">
            <Portada />
          </Row>
          <Row id="body">
            <Outlet />
          </Row>
          <Row id="footer">
            <Footer />
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Layout;
