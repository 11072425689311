import React, { useState, useEffect } from "react";
import { Row, Container, Col } from "react-bootstrap";
import "./style.css";
const EnConstruccion = () => {
  const [contador] = useState(0);
  useEffect(() => {
    let container_all = document.getElementById("container-body");
    let header = document.getElementById("header");
    let portada = document.getElementById("portada");
    container_all.scrollTo(0, header.scrollHeight + portada.scrollHeight);
  }, [contador]);
  return (
    <Container fluid className="construccion-container">
      <Row>
        <h1 style={{ textAlign: "center", fontWeight: "900" }}>
          PLANOS STANDS
        </h1>
      </Row>
      <Row>
        <Col xs={12} md={6} lg={6}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/PLANOS+CLACE+2024+PB+(7).jpg"
            alt="planos"
            className="plano"
          />
        </Col>
        <Col xs={12} md={6} lg={6}>
          <img
            src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/WhatsApp+Image+2024-06-06+at+16.18.38.jpeg"
            alt="planos"
            className="plano"
          />
        </Col>
      </Row>
    </Container>
  );
};

export default EnConstruccion;
