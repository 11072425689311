import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import { Row } from "react-bootstrap";
import React from "react";
import "./style.css";
function Header(props) {
  return (
    <Container fluid>
      {/* <Row style={{ display: "grid", placeItems: "center", marginTop: "5%" }}>
        <img
          src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Logo+COLADE+2023+final+(1).png"
          alt="header"
          style={{ width: "55%", objectFit: "contain" }}
        />
      </Row>
      <Row style={{ display: "grid", placeItems: "center" }}>
        <img
          src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/FECHAS+Y+LUGAR+COLADE.png"
          alt="header"
          style={{ width: "45%", objectFit: "contain" }}
        />
      </Row>*/}

      <Row>
        <Navbar collapseOnSelect expand="xxl" bg="light" variant="light">
          <Container>
            <Navbar.Toggle aria-controls="responsive-navbar-nav justify-content-center" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="mx-auto">
                <Nav.Link href="/">INICIO</Nav.Link>
                <Nav.Link href="/organizadores">ORGANIZADORES</Nav.Link>
                <Nav.Link
                  href="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Brief+FELABAN+CLACE+2024+(3).pdf"
                  target="_blank"
                >
                  PATROCINIOS
                </Nav.Link>
                <NavDropdown title="VENUE" id="collasible-nav-dropdown">
                  <NavDropdown.Item href="/planos">PLANOS</NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://www.tripadvisor.co/Restaurants-g295366-zfp43-Antigua_Sacatepequez_Department.html"
                    target="_blank"
                  >
                    RESTAURANTES
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown
                  title="REGISTRO Y HOSPEDAJE"
                  id="collasible-nav-dropdown"
                >
                  <NavDropdown.Item
                    href="https://registro.felaban.net/congreso/74"
                  >
                    REGISTRO A CLACE
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    href="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Brief+hoteles+y+transporte+CLACE+(1).pdf"
                    target="_blank"
                  >
                    HOTELES Y TRANSPORTE
                  </NavDropdown.Item>
                  <NavDropdown.Item href="https://www.casasantodomingo.com.gt/es?gad_source=1&gclid=Cj0KCQiAn-2tBhDVARIsAGmStVmy17c7x1a1wf5ng6emp1Rlr7T8EVMmDPGgXEWI1ai0SxOIPY7fg0EaAufzEALw_wcB&gclsrc=aw.ds">
                    HOSPEDAJE HOTEL CASA SANTO DOMINGO
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/informacion-importante-para-tu-viaje">
                    INFORMACIÓN IMPORTANTE PARA TU VIAJE
                  </NavDropdown.Item>
                </NavDropdown>
                <NavDropdown title="PROGRAMA" id="collasible-nav-dropdown">
                  <NavDropdown.Item
                    href="/programa"
                  >
                    PROGRAMA ACADÉMICO
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/galeria">
                    GALERIA DE LA EXPERIENCIA CLACE
                  </NavDropdown.Item>
                  <NavDropdown.Item href="/tours">TOURS</NavDropdown.Item>
                </NavDropdown>
              </Nav>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </Row>
    </Container>
  );
}

export default Header;
