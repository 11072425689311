import React from "react";
import { Row, Container } from "react-bootstrap";
import "./style.css";
const Home = () => {
  return (
    <Container fluid className="home-container">
      <Row style={{ padding: "5%" }}>
        <Container fluid>
          <Row className="d-flex justify-content-center align-items-center">
            <img
              src="https://escuela-bancaria.s3.us-east-2.amazonaws.com/Banner+PW+CLACE+2024+(1).jpg"
              alt="header"
            />
          </Row>
          <Row className="mt-5">
            <h1>¿Qué es Clace?</h1>
          </Row>
          <Row className="mt-5">
            <p style={{ textAlign: "justify" }}>
              El Congreso Latinoamericano de Comercio Exterior – CLACE se ha
              posicionado, durante las últimas décadas, como el foro académico
              por excelencia de los directivos de los sectores bancarios y
              financieros encargados de otorgar financiamiento a las operaciones
              de comercio internacional del sector real latinoamericano. El
              congreso analiza el impacto trasversal de otras unidades de
              negocio bancario en el financiamiento de exportaciones e
              importaciones, el impacto de las nuevas tecnologías, las
              perspectivas normativas en términos regulatorios, las mejores
              prácticas corporativas para dinamizar e impulsar el financiamiento
              del comercio exterior interregional e intrarregional. en América
              Latina. El Congreso CLACE también es provechoso para toda entidad
              del sector financiero ampliado interesada en concretar nuevos
              negocios con los bancos comerciales, bancos corresponsales,
              proveedores de tecnología y otras entidades financieras que
              periódicamente asisten a este evento.
            </p>
          </Row>
          <Row className="mt-5">
            <h1>¡Visita Guatemala!</h1>
          </Row>
          <Row className="mt-5">
            <iframe
              width="100%"
              height="600px"
              src="https://www.youtube-nocookie.com/embed/vCccxVvXrTk?si=ebqIdh3i4l9FLJwf"
              title="YouTube video player"
              frameborder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            ></iframe>
          </Row>
        </Container>
      </Row>
    </Container>
  );
};

export default Home;
